import React, { useState } from "react";
import { Button, Form, Container, Modal } from "react-bootstrap";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import "../css/login.css";

const LoginComponent = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
      toast.success("Successfully Logged In!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordModal(true);
  };

  const closeForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      // Send a password reset email to the provided email address
      await sendPasswordResetEmail(auth, resetEmail);

      toast.success("Password reset email sent. Please check your inbox.");
      closeForgotPasswordModal();
    } catch (error) {
      toast.error("Error sending password reset email. Please try again.");
    }
  };

  return (
    <Container className="mt-3 width">
      <h5 className="my-4 fw-bold text-center">Sign in to book your stay</h5>
      <hr className="mb-4"></hr>
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="email" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Email</p>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            className="mx-auto"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="password" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Password</p>
          </Form.Label>
          <Form.Control
            type="password"
            className="mx-auto"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button type="submit" className="w-100" variant="primary">
          Submit
        </Button>
        <div className="text-center my-3 mb-5">
          <Button variant="" onClick={handleForgotPassword}>
            <a>Forgot Password?</a>
          </Button>
        </div>
        <hr className="mb-4"></hr>
        <p className="my-4 text-center">
          Don't have an account?{" "}
          <span>
            <a href="/register" className="register-link">
              Create an account
            </a>
          </span>
        </p>
      </Form>

      <Modal show={showForgotPasswordModal} onHide={closeForgotPasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleResetPassword}>
            <Form.Group controlId="forgotPasswordEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Reset Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default LoginComponent;
