import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory
import { loadStripe } from "@stripe/stripe-js";
import "../components/stylesheets/styles.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import loadingGif from "../components/img/loading.gif";
import { Container, Image } from "react-bootstrap";

let stripePromise;
const stripe_key = process.env.REACT_APP_STRIPE_KEY;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripe_key);
  }

  return stripePromise;
};

const Checkout = () => {
  const location = useLocation();
  const { nights, id } = location.state;
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  let item;

  const navigate = useNavigate(); // Get the navigate function

  const details = async () => {
    if (id === 1) {
      item = {
        price: "price_1OJgOALX3tpiw3cj1vNM6KGV",
        quantity: nights,
      };
    } else if (id === 2) {
      item = {
        price: "price_1OJIzsLX3tpiw3cjnUtRVilB",
        quantity: nights,
      };
    }
  }

  const redirectToCheckout = async () => {
    if (!item) {
      navigate("/");
      return;
    }
    const checkoutOptions = {
      lineItems: [
        {
          price: item.price,
          quantity: item.quantity,
        },
      ],
      mode: "payment",
      successUrl: `${window.location.origin}/Success?id=${id}`,
      cancelUrl: `${window.location.origin}/Cancel?id=${id}`,
    };
    setLoading(true);
  
    const stripe = await getStripe();
  
    try {
      const { error } = await stripe.redirectToCheckout(checkoutOptions);
      if (error) {
        setStripeError(error);
        setLoading(false);
      }
    } catch (error) {
      setStripeError(error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    details();
    redirectToCheckout();
  }, []);

  if (stripeError) toast.error(stripeError);

  return (
    <Container className="d-flex align-items-center justify-content-center mt-5" style={{ height: '100vh' }}>
      <Image
        src={loadingGif}
        alt="Loading"
        width="190"
        height="auto"
        className="loadingGif"
      />
    </Container>
  );
};

export default Checkout;
