import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "@firebase/firestore";
import Navigation from "../nav/Navbar";

const UserBookingsPage = () => {
  const [user, setUser] = useState(null);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserBookings(user.uid);
    }
  }, [user]);

  const fetchUserBookings = async () => {
    try {
      const bookingsRef = collection(db, "bookings");
      const userBookings = await getDocs(
        query(bookingsRef, where("uid", "==", user.uid))
      );

      const bookingsData = [];
      userBookings.forEach((doc) => {
        bookingsData.push({ id: doc.id, ...doc.data() });
      });

      setBookings(bookingsData);
    } catch (error) {
      console.error("Error fetching user bookings:", error);
    }
  };

  return (
    <>
      <Navigation />
      <Container>
        <h2>User Bookings</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.id}>
                <td>{booking.id}</td>
                <td>{booking.startDate}</td>
                <td>{booking.endDate}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default UserBookingsPage;
