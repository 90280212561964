import { Nav, Container, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { toast } from "react-toastify";
import "../css/navbar.css";

function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        setIsLoggedIn(false);
        toast.success("Successfully Logged Out");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
        toast.error("Error logging out");
      });
  };

  const location = useLocation();
  return (
    <>
      <Navbar collapseOnSelect bg="white" expand="lg" className="py-2 navbar w-100 mt-3">
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="text-start ms-3">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/info"
                className={`text-gray-500 nav-styles ${
                  location.pathname === "/info" ? "active" : ""
                }`}
              >
                Info about the Owner
              </Nav.Link>
            </Nav.Item>
          </Navbar.Collapse>
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center text-center me-4">
            <Nav className="mx-auto">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`text-gray-500 nav-styles ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  Sea Breeze
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/dinerth-cottage"
                  className={`text-gray-500 nav-styles ${
                    location.pathname === "/dinerth-cottage" ? "active" : ""
                  }`}
                >
                  Dinerth Cottage
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="responsive-navbar-nav" className="text-end me-3">
            <Nav className="ms-auto">
              {!isLoggedIn && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/login"
                    className={`text-gray-500 nav-styles ${
                      location.pathname === "/login" ? "active" : ""
                    }`}
                  >
                    Login
                  </Nav.Link>
                </Nav.Item>
              )}
              {isLoggedIn && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="/user-bookings"
                      className={`text-gray-500 nav-styles ${
                        location.pathname.startsWith("/user-bookings")
                          ? "active"
                          : ""
                      }`}
                    >
                      {auth.currentUser.email}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="text-gray-500 nav-styles">
                      <a
                        href="/"
                        className="text-decoration-none"
                        onClick={handleLogout}
                      >
                        Log Out
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr className="thicker-hr mx-auto" />
    </>
  );
}

export default Navigation;
