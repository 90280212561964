import React, { useState } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import propertyData from "./property.json";
import {
  FaBaby,
  FaChair,
  FaParking,
  FaRegBuilding,
  FaSuitcase,
  FaTree,
  FaTv,
  FaWater,
  FaWifi,
} from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

export default function AnnexInformation() {
  const toggleReadMore = () => {
    var readMoreContent = document.getElementById("readMoreContent");
    var readMoreButton = document.getElementById("readMoreButton");

    if (
      readMoreContent.style.display === "none" ||
      readMoreContent.style.display === ""
    ) {
      readMoreContent.style.display = "block";
      readMoreButton.innerHTML = "Read Less...";
    } else {
      readMoreContent.style.display = "none";
      readMoreButton.innerHTML = "Read More...";
    }
  };

  const [showLocationDetails, setShowLocationDetails] = useState(false);

  const toggleLocationDetails = () => {
    setShowLocationDetails(!showLocationDetails);
  };

  return (
    <>
      <Row>
        <Row>
          <Col>
            <hr />
            <h5 className="mb-2">Information about the property</h5>
            <Container>
              <p>
                Nestled in a valley with views of the Snowdonia foothills,
                you’ll find our cute little Dinerth Cottage. It’s completely
                self-contained and features one bedroom with two singles, a
                shower wc, a kitchenette living room with a double sofa bed. It
                has central heating, Netflix & Free Parking.
              </p>
              <p>
                It’s a quiet & peaceful area but near to pubs, cinema,
                nightclub, cafes, restaurants and shops. Llandudno, Conwy, Betws
                y Coed, Caernarfon. Great for Rugby, Water Sports, Concerts, &
                Outdoor pursuits.
              </p>
            </Container>
            <div id="readMoreContent">
              <Container>
                <p>
                  Although you have your own place, it dwells within a shared
                  garden belonging to the owners. It is quite a large and wild
                  garden, with different areas for you to sit and relax, it also
                  backs directly on to a nature reserve of woodland and pasture
                  at the top. Bryn Euryn is a beautiful 131m (430ft) hill with a
                  360 view at the top.
                </p>
              </Container>
            </div>
            <Button
              id="readMoreButton"
              variant=""
              className="mb-4 fw-bold"
              onClick={() => toggleReadMore()}
            >
              Read More...
            </Button>
          </Col>
        </Row>
        <hr />

        <Row className="my-4">
          <h5>Amenities:</h5>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FaWater className="icon" /> Public or shared beach access
              </ListGroup.Item>
              <ListGroup.Item>
                <FaParking className="icon" /> Free parking on premises
              </ListGroup.Item>
              <ListGroup.Item>
                <FaWifi className="icon" /> Dryer
              </ListGroup.Item>
              <ListGroup.Item>
                <FaTree className="icon" /> Private back garden – Fully fenced
              </ListGroup.Item>
              <ListGroup.Item>
                <FaBaby className="icon" /> Travel cot – available upon request
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FaWifi className="icon" /> Wifi
              </ListGroup.Item>
              <ListGroup.Item>
                <FaTv className="icon" /> 45" HDTV with Netflix
              </ListGroup.Item>
              <ListGroup.Item>
                <FaRegBuilding className="icon" /> Shared patio or balcony
              </ListGroup.Item>
              <ListGroup.Item>
                <FaSuitcase className="icon" /> Luggage drop-off allowed
              </ListGroup.Item>
              <ListGroup.Item>
                <FaChair className="icon" /> Booster seat high chair – available
                upon request
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <hr />
        <h5>Location</h5>
        <p className="mt-3">
          <MdLocationPin size={28}></MdLocationPin>
          {propertyData.location}
        </p>
        <div className="iFrame" id="map">
          <iframe
            className="my-3"
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.2587420657424!2d-3.763091524138011!3d53.302812277981026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48651f966639b3b9%3A0xe012b09117b4652f!2sDinerth%20Rd%2C%20Rhos%20on%20Sea%2C%20Colwyn%20Bay!5e0!3m2!1sen!2suk!4v1698335479973!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen={false}
            loading="lazy"
          ></iframe>
          <Row className="my-4">
            <Col>
              <h5 className="font-weight-bold">About the Location:</h5>
              <p>Rhos on Sea, Wales, United Kingdom</p>
              <Container>
                <p>
                  Our home backs onto the Bryn Euryn nature reserve, within
                  minutes you can be walking in woodland and seeing 360' views
                  over to Snowdon, Anglesey, and even Liverpool. Rhos on Sea
                  village is five minutes away by car where there are plenty of
                  cafe's pubs and takeaways. The holiday towns of Llandudno and
                  Colwyn Bay are a 10-minute trip with Shops, Cafes, Cinema,
                  Live Bands, Concerts, Rugby. 30 minutes from Surf Snowdonia,
                  Climbing, Zip Wire, Bounce Below, Snowdonia National Park.
                </p>
              </Container>
              {showLocationDetails && (
                <div id="readMoreLocation">
                  <Container>
                    <p>Getting around</p>
                    <p>
                      There is a bus stop within 100m of our property which will
                      take you to all areas of the coast. There are also many
                      bike and walking routes nearby.
                    </p>
                    <p>
                      Although you have your own place, it dwells within a
                      shared garden belonging to the owners. It is quite a large
                      and wild garden, with different areas for you to sit and
                      relax, it also backs directly on to a nature reserve of
                      woodland and pasture at the top. Bryn Euryn is a beautiful
                      131m (430ft) hill with a 360 view at the top.
                    </p>
                  </Container>
                </div>
              )}
              <Button
                id="locationDetailsButton"
                variant=""
                className="mb-4 fw-bold"
                onClick={() => toggleLocationDetails()}
              >
                {showLocationDetails ? "Read Less..." : "Read More..."}
              </Button>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
}
