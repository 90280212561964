import React, { useState } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import propertyData from "../components/property.json";
import {
  FaBaby,
  FaBabyCarriage,
  FaChair,
  FaFan,
  FaLaptop,
  FaParking,
  FaTv,
  FaUtensils,
  FaWater,
  FaWifi,
} from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

export default function PropertyInformation() {
  const toggleReadMore = () => {
    var readMoreContent = document.getElementById("readMoreContent");
    var readMoreButton = document.getElementById("readMoreButton");

    if (
      readMoreContent.style.display === "none" ||
      readMoreContent.style.display === ""
    ) {
      readMoreContent.style.display = "block";
      readMoreButton.innerHTML = "Read Less...";
    } else {
      readMoreContent.style.display = "none";
      readMoreButton.innerHTML = "Read More...";
    }
  };

  const [showLocationDetails, setShowLocationDetails] = useState(false);

  const toggleLocationDetails = () => {
    setShowLocationDetails(!showLocationDetails);
  };

  return (
    <>
      <Row>
      <hr />
        <h5 className="mb-2">Information about the property</h5>
        <Row>
          <Col>
            <Container>
              <p>
                Resting above an estate agent, in the heart of the seaside
                resort of Rhos-on-Sea, is this delightful first-floor apartment.
              </p>
              <p>
                You are resting within a desirable location, only a few minutes'
                walk from the wonderful Welsh coastline and a number of shops,
                pubs, and restaurants.
              </p>
              <p>
                Walking through the front door, you ascend a set of stairs to
                the first floor, and through to a spacious hallway, where you
                can drop off your coats and muddy boots.
              </p>
              <p>
                Resting to the front of the apartment are three bedrooms,
                consisting of a king-size, twin, and family room with a double
                and single setup, all of which have been furnished to a high
                standard with ample storage space.
              </p>
            </Container>
            <div id="readMoreContent">
              <Container>
                <p>
                  There are steps leading down to a family shower room, with
                  deluxe fixtures and fittings so you can enjoy a warm soak to
                  prepare for your day.
                </p>
                <p>
                  Discover an open-plan living space towards the rear,
                  delightfully decorated with quality furnishings and a light
                  decor, giving the room that warm and welcoming feeling.
                </p>
                <p>
                  Look forward to spending your evenings curled up on the corner
                  sofa, with a TV so you can catch up on all your favorite shows
                  and discuss the day's events.
                </p>
                <p>
                  The dining area provides ample space for gatherings over a
                  delicious home-cooked meal and a glass of wine, whilst the
                  well-equipped kitchen provides a small breakfast table to
                  enjoy a hearty breakfast whilst planning the day's events.
                </p>
                <p>
                  Situated in the town center of Rhos-on-Sea, you are several
                  minutes' walk from the delightful Rhos-on-Sea beach, where you
                  can spend your afternoons bathing in the sun, or taking a long
                  and relaxing swim in the shallow waters, before enjoying a
                  delightful picnic spread across your beach towels.
                </p>
                <p>
                  You are within reach of the Welsh Mountain Zoo, with
                  picturesque views and housing rare animals inside, such as red
                  pandas and snow leopards, something your little ones will
                  delight in.
                </p>
                <p>
                  Little Orme is a wonderful nature reserve area, perfect for
                  taking long coastal walks, and also getting the opportunity
                  for a variety of other watersporting activities such as
                  surfing and kayaking.
                </p>
                <p>
                  The neighboring seaside town of Llandudno is only a short
                  drive away and boasts the magnificent Victorian Llandudno
                  Pier, with a range of stalls and a bar for your enjoyment, as
                  well as a copper mine, Viking site, and a ski slope.
                </p>
                <p>
                  The limestone headland of the Great Orme is the ultimate place
                  to visit for those more active types, with ample walking and
                  hiking opportunities and picturesque views across the
                  countryside.
                </p>
                <p>
                  Close by in Colwyn Bay, there's a rugby stadium, outdoor
                  concert venue, swimming pool, gym, various tennis and other
                  sports and athletics.
                </p>
                <p>
                  There’s also a number of food and drink outlets, a cineworld
                  nearby, Venue Cymru for concerts and the Alice In Wonderland
                  trail.
                </p>
                <p>
                  Conwy with its delightful shops, cafes, pubs, and the historic
                  castle is within a 20 to 30-minute drive.
                </p>
                <p>
                  Also within reach is Betws-y-Coed, Llanberis, and Snowdonia.
                </p>
                <p>
                  This is for the outdoors enthusiast with Surf Snowdonia, quad
                  biking, mountain biking, climbing, rambling, Bounce Below
                  trampoline cave, zip wire, white water rafting, and canoeing,
                  plus plenty more.
                </p>
                <p>
                  Sea Breeze is the perfect choice for an adventure-filled
                  holiday to the coast of North Wales.
                </p>
              </Container>
            </div>
            <Button
              id="readMoreButton"
              variant=""
              className="mb-4 fw-bold"
              onClick={() => toggleReadMore()}
            >
              Read More...
            </Button>
          </Col>
        </Row>
        <hr />

        <Row className="my-4">
          <h5>Amenities:</h5>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FaWater className="icon" /> Public or shared beach access
              </ListGroup.Item>
              <ListGroup.Item>
                <FaUtensils className="icon" /> Kitchen
              </ListGroup.Item>
              <ListGroup.Item>
                <FaWifi className="icon" /> Wifi – 36 Mbps
              </ListGroup.Item>
              <ListGroup.Item>
                <FaLaptop className="icon" /> Dedicated workspace
              </ListGroup.Item>
              <ListGroup.Item>
                <FaParking className="icon" /> Free parking on premises
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FaTv className="icon" /> 40" HDTV
              </ListGroup.Item>
              <ListGroup.Item>
                <FaBabyCarriage className="icon" /> Cot
              </ListGroup.Item>
              <ListGroup.Item>
                <FaBaby className="icon" /> Travel cot
              </ListGroup.Item>
              <ListGroup.Item>
                <FaChair className="icon" /> High chair
              </ListGroup.Item>
              <ListGroup.Item>
                <FaFan className="icon" /> Hair dryer
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <p className="Note">
          Please note: Up to 2 well-behaved dogs welcome at an additional charge
          of £25 per pet, per stay.
        </p>
        <p className="Note">
          Please Note: Pets must NOT be allowed on the furniture or the beds.
        </p>
        <p className="Note">
          Please note: Cot and Highchair available on request.
        </p>
        <hr />
        <h5>
          Location
        </h5>
        <p className="mt-3"><MdLocationPin size={28}></MdLocationPin>{propertyData.location}</p>
        <div className="iFrame" id="map">
          <iframe
            className="my-3"
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.0242605299195!2d-3.7429705241376876!3d53.30701177766696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48651f8c655b5f75%3A0x6d7f7f3e4f62f68c!2sEverard%20Rd%2C%20Rhos%20on%20Sea%2C%20Colwyn%20Bay!5e0!3m2!1sen!2suk!4v1696437405430!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen={false}
            loading="lazy"
          ></iframe>
          <Row className="my-4">
        <Col>
          <h5 className="font-weight-bold">About the Location:</h5>
          <p>Rhos-on-Sea,
            Colwyn Bay 2 miles; Llandudno 4 miles; Betws-y-Coed 18 miles.
          </p>
          <Container>
            <p>
              The elegant seaside resort of Rhos-on-Sea is a coastal town in North Wales. With its picturesque harbour and relaxed atmosphere, it is the ideal location for a peaceful holiday. Situated in the shadow of the towering Bryn Euryn, with Penrhyn Bay to the west and Colwyn Bay to the east, there is plenty to keep everybody occupied. Energetic visitors can climb the 365 feet of Bryn Euryn and discover the fascinating hill fort 'Dinerth' at the top, while those who prefer a more relaxed walk can stroll along the promenade, taking in the beautiful surroundings overlooking the Irish Sea. Spend a day on the beach at Colwyn Bay, enjoying the abundance of water sports available at Port Eirias, where boats, kayaks, and windsurfers can be hired. Take a boat trip out to spot seals, porpoises, and dolphins, or enjoy a round of golf at Rhos-on-Sea's golf club. With lots to do close by, and beautiful Conwy Castle and the Victorian seaside town of Llandudno within a short drive, there is never a shortage of things to do in Rhos-on-Sea.
            </p>
          </Container>
          {showLocationDetails && (
            <div id="readMoreLocation">
              <Container>
                <p>
                  Additional details about the location can go here.
                </p>
              </Container>
            </div>
          )}
          <Button
            id="locationDetailsButton"
            variant=""
            className="mb-4 fw-bold"
            onClick={() => toggleLocationDetails()}
          >
            {showLocationDetails ? "Read Less..." : "Read More..."}
          </Button>
        </Col>
      </Row>
        </div>
      </Row>
    </>
  );
}
