import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
import "../css/Info.css";
import OwnerPic from "../components/img/Ginny&Rob.jpg";
import Navigation from "../nav/Navbar";

const Info = () => {
  return (
    <>
      <Navigation />
      <div className="info-container">
        <Container>
          <Row>
            <Col>
              <div className="info-content">
                <h1>Robert & Ginny</h1>
                <img src={OwnerPic} alt="Owner's" className="owner-image" />
                <p>
                  Welcome to our property! We are here to make your stay
                  comfortable and enjoyable. If you have any questions or need
                  assistance, feel free to contact us.
                </p>
                <div className="contact-info">
                  <div className="contact-item">
                    <FaUser className="contact-icon" />
                    <p>Robert Pierce</p>
                  </div>
                  <div className="contact-item">
                    <FaPhone className="contact-icon" />
                    <p>07815 870 350</p>
                  </div>
                  <div className="contact-item">
                    <FaEnvelope className="contact-icon" />
                    <p>robpristine@gmail.com</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Info;
