import React, { useState } from "react";
import { Button, Form, Container, Alert } from "react-bootstrap";
import { createUserWithEmailAndPassword, sendEmailVerification } from "@firebase/auth";
import { toast } from "react-toastify";
import { auth, db } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import "../css/login.css";
import { addDoc, collection } from "@firebase/firestore";

const RegistrationPage = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Validation state for each field
  const [nameError, setNameError] = useState(null);
  const [surnameError, setSurnameError] = useState(null);
  const [phonenumberError, setPhonenumberError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const handleSuccessfulRegistration = () => {
    toast.success("User Registered. Please check your email for verification instructions. Then refresh page");
    setName("");
    setSurname("");
    setEmail("");
    setPhonenumber("");
    setPassword("");
    setConfirmPassword("");
    navigate("/"); // Redirect to the login page or home page
  };

  const validateForm = () => {
    // Validation rules
    const namePattern = /^.{3,}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phonePattern = /^[0-9]{11}$/;
    const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

    // Reset errors
    setNameError(null);
    setSurnameError(null);
    setPhonenumberError(null);
    setEmailError(null);
    setPasswordError(null);
    setConfirmPasswordError(null);

    let isValid = true;

    if (!namePattern.test(name)) {
      setNameError("Name must be at least 3 characters");
      isValid = false;
    }

    if (!namePattern.test(surname)) {
      setSurnameError("Surname must be at least 3 characters");
      isValid = false;
    }

    if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    }

    if (!phonePattern.test(phonenumber)) {
      setPhonenumberError("Invalid phone number");
      isValid = false;
    }

    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must have at least 8 characters, one uppercase letter, and one number"
      );
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await sendEmailVerification(user); // Send email verification

        const userId = user.uid;

        const userRef = collection(db, "users");
        await addDoc(userRef, {
          uid: userId,
          name,
          surname,
          email,
          phonenumber,
        });

        handleSuccessfulRegistration();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container className="mt-3 width">
      <h5 className="my-4 fw-bold text-center">Create an Account</h5>
      <hr className="mb-4"></hr>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Name</p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            className="mx-auto"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <Alert variant="danger">{nameError}</Alert>}
        </Form.Group>

        <Form.Group controlId="surname" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Surname</p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter surname"
            className="mx-auto"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          {surnameError && <Alert variant="danger">{surnameError}</Alert>}
        </Form.Group>

        <Form.Group controlId="email" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Email</p>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            className="mx-auto"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <Alert variant="danger">{emailError}</Alert>}
        </Form.Group>

        <Form.Group controlId="phonenumber" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Phone Number</p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            className="mx-auto"
            value={phonenumber}
            onChange={(e) => setPhonenumber(e.target.value)}
          />
          {phonenumberError && (
            <Alert variant="danger">{phonenumberError}</Alert>
          )}
        </Form.Group>

        <Form.Group controlId="password" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Password</p>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            className="mx-auto"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <Alert variant="danger">{passwordError}</Alert>}
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="mb-4">
          <Form.Label className="label">
            <p className="custom-p">Confirm Password</p>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            className="mx-auto"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmPasswordError && (
            <Alert variant="danger">{confirmPasswordError}</Alert>
          )}
        </Form.Group>

        <Button type="submit" className="w-100" variant="primary">
          Register
        </Button>
        <hr className="mb-4"></hr>
        <p className="my-4 text-center">
          Already have an account?{" "}
          <span>
            <a href="/login" className="register-link">
              Sign in
            </a>
          </span>
        </p>
      </Form>
    </Container>
  );
};

export default RegistrationPage;
