import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import "../css/Cancel.css"; // Create a CSS file for styling

const Cancel = () => {
  return (
    <div className="cancel-container">
      <Container>
        <Row>
          <Col>
            <div className="cancel-content">
              <FaTimesCircle className="cancel-icon" />
              <h1>Your Payment Was Cancelled</h1>
              <p>
                Unfortunately, your payment has been cancelled. If you would
                like to try again, please return to the checkout page.
              </p>
              <a href="/">Return home</a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Cancel;
