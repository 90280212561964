import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Gallery from "react-image-gallery";
import mainPic from "../components/img/Annex/AnnexMain1.jpg";
import mainPic2 from "../components/img/Annex/AnnexMain2.jpg";
import Kitchen from "../components/img/Annex/AnnexKitchen1.jpg";
import Kitchen2 from "../components/img/Annex/AnnexKitchen2.jpg";
import Bed1 from "../components/img/Annex/AnnexBed1.jpg";
import Bed2 from "../components/img/Annex/AnnexBed2.jpg";
import Bed3 from "../components/img/Annex/AnnexBed3.jpg";
import Bathroom1 from "../components/img/Annex/AnnexBathroom1.jpg";
import Bathroom2 from "../components/img/Annex/AnnexBathroom2.jpg";
import AnnexGarden1 from "../components/img/Annex/AnnexGarden1.jpg";
import AnnexGarden2 from "../components/img/Annex/AnnexGarden2.jpg";
import AnnexGarden3 from "../components/img/Annex/AnnexGarden3.jpg";
import AnnexGarden4 from "../components/img/Annex/AnnexGarden4.jpg";
import AnnexPatio from "../components/img/Annex/AnnexPattio.jpg";
import AnnexOutside from "../components/img/Annex/AnnexOutside.jpg";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "../css/index.css";
import propertyData from "../components/annex.json";
import { MdLocationPin } from "react-icons/md";

import axios from "axios";
import { DateRange } from "react-date-range";
import MemberBookModalComponent from "../components/modals/MemberBookModalComponent";
import { toast } from "react-toastify";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import AnnexInformation from "../components/AnnexInformation";
import Navigation from "../nav/Navbar";

const DinerthCottage = () => {
  const [cost, setCost] = useState(110);
  const id = 2;
  const [nights, setNights] = useState(1);
  const [events, setEvents] = useState([]);
  const [showMemberBookModal, setShowMemberBookModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const API_ENDPOINT = `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_CALENDAR_ID}/events?key=${process.env.REACT_APP_API_KEY}`;
    axios
      .get(API_ENDPOINT)
      .then((response) => {
        const events = response.data.items;
        setEvents(events);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        setIsLoggedIn(true);
      } else {
        // User is not logged in
        setIsLoggedIn(false);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  const unavailableDates = events.map((event) => new Date(event.start.date));

  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (selectedRanges) => {
    const startDate = selectedRanges.selection.startDate;
    const endDate = selectedRanges.selection.endDate;
    const numberOfNights = Math.floor(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    setNights(numberOfNights);
    const newCost = propertyData.price * numberOfNights;
    if (newCost === 0) {
      setCost(propertyData.price);
    } else {
      setCost(newCost);
    }
    setRanges([selectedRanges.selection]);
  };

  const openMemberBookModal = (ranges) => {
    if (nights) {
      setShowMemberBookModal(true);
    } else {
      toast.error("Invalid date selection");
    }
  };

  const closeModal = () => {
    setShowMemberBookModal(false);
  };
  const loginRedirect = () => {
    navigate("/login");
  };

  const propertyImages = {
    images: [
      {
        original: AnnexGarden4,
        thumbnail: AnnexGarden4,
        description: "Outside the Annex",
      },
      {
        original: mainPic,
        thumbnail: mainPic,
        description: "Living Room",
      },
      {
        original: mainPic2,
        thumbnail: mainPic2,
        description: "Living Room",
      },
      {
        original: Kitchen,
        thumbnail: Kitchen,
        description: "Kitchen",
      },
      {
        original: Kitchen2,
        thumbnail: Kitchen2,
        description: "Kitchen",
      },
      {
        original: Bed1,
        thumbnail: Bed1,
        description: "Main Bedroom Picture 1",
      },
      {
        original: Bed2,
        thumbnail: Bed2,
        description: "Main Bedroom Picture 2",
      },
      {
        original: Bed3,
        thumbnail: Bed3,
        description: "Main Bedroom Picture 3",
      },
      {
        original: Bathroom1,
        thumbnail: Bathroom1,
        description: "Bathroom Picture 1",
      },
      {
        original: Bathroom2,
        thumbnail: Bathroom2,
        description: "Bathroom Picture 2",
      },
      {
        original: AnnexGarden1,
        thumbnail: AnnexGarden1,
        description: "Garden Picture 1",
      },
      {
        original: AnnexGarden2,
        thumbnail: AnnexGarden2,
        description: "Garden Picture 2",
      },
      {
        original: AnnexGarden3,
        thumbnail: AnnexGarden3,
        description: "Garden Picture 3",
      },
      {
        original: AnnexPatio,
        thumbnail: AnnexPatio,
        description: "Patio",
      },
      {
        original: AnnexOutside,
        thumbnail: AnnexOutside,
        description: "Outside",
      },
    ],
  };

  return (
    <>
      <Navigation />
      <Container className="mt-5">
        <Row>
          <Col md={8}>
            <Gallery
              items={propertyImages.images}
              showFullscreenButton={false}
            />
          </Col>
          <Col md={4}>
            <h3 className="fw-bold mb-2">{propertyData.title}</h3>
            <div className="key_features">
              {propertyData.key_features.map((key_features, index, array) => (
                <React.Fragment key={index}>
                  <p className="fs-6 mb-2">{key_features}</p>
                  {index === array.length - 1 ? null : (
                    <p className="mx-1">·</p>
                  )}
                </React.Fragment>
              ))}
            </div>
            <Row>
              <a href="#map" className="text-decoration-underline">
                <MdLocationPin size={28}></MdLocationPin>
                {propertyData.location}
              </a>
            </Row>
            <p className="mt-5 mb-0 fs-5 fw-bold">
              £{propertyData.price}/Night
            </p>
            <Row>
              <DateRange
                ranges={ranges}
                onChange={handleSelect}
                minDate={new Date()}
                disabledDates={unavailableDates}
              />
            </Row>
            <p className="text-secondary mb-5 ms-4">
              £{propertyData.price} x {nights} nights
            </p>
            {isLoggedIn && (
              <Row>
                <Button
                  className="mx-auto w-50"
                  variant="primary"
                  onClick={() => openMemberBookModal(ranges)}
                >
                  Book Now
                </Button>
              </Row>
            )}
            {!isLoggedIn && (
              <Row>
                <Button
                  className="mx-auto w-50"
                  variant="primary"
                  onClick={() => loginRedirect()}
                >
                  Continue
                </Button>
              </Row>
            )}
            <hr />
            {ranges && (
              <p className="fw-bold">
                <span>Total :</span>
                <span className="float-end">£{cost}</span>
              </p>
            )}
          </Col>

          {showMemberBookModal && (
            <MemberBookModalComponent
              closeModal={closeModal}
              nights={nights}
              ranges={ranges}
              id={id}
            />
          )}
        </Row>
        <AnnexInformation />
      </Container>
    </>
  );
};

export default DinerthCottage;
