import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyATOd8QFCER4asLdh6BW94p9O7kibNkT7w",
  authDomain: "myhome-b1487.firebaseapp.com",
  projectId: "myhome-b1487",
  storageBucket: "myhome-b1487.appspot.com",
  messagingSenderId: "87298990443",
  appId: "1:87298990443:web:dbf8585528f3ac0a9fec2e",
  measurementId: "G-RT19G8YT0K"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);


