import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./Routes";
import Navigation from "./nav/Navbar";
import Footer from "./nav/footer";
import { BrowserRouter } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { auth } from "./firebase";

const root = ReactDOM.createRoot(document.getElementById("root"));

auth.onAuthStateChanged((user) => {
  root.render(
    <BrowserRouter>
      <div className="main">
        <div className="content">
          <ToastContainer />
          <App user={user} />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
});
