import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { auth, db } from "../../firebase";
import { addDoc, collection, getDocs, orderBy, query } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MemberBookModalComponent = (props) => {
  const navigate = useNavigate();
  const { closeModal, ranges, nights, id } = props;
  const range = ranges[0]; // Access the first item in the array
  const { startDate, endDate } = range;

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2); // Extract the last two digits of the year
    return `${day}/${month}/${year}`;
  };

  // Format the start and end dates
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const handleSuccessfulBooking = () => {
    toast.info("Booking Awaiting Payment");
    closeModal();
    navigate("/checkout", { state: { nights: nights, id: id } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;

      if (user) {
        if (!user.emailVerified) {
          // Check if the user's email is verified
          toast.warn("Please verify your email address before making a booking. Try refreshing your page if you have");
          closeModal();
          return;
        }

        const userId = user.uid;
        const bookingRef = collection(db, "bookings"); // Firestore collection reference

        const querySnapshot = await getDocs(query(bookingRef, orderBy('id', "desc"))); // Corrected: Use query and where functions
        const lastBooking = querySnapshot.docs[0];
        const lastBookingData = lastBooking.data();
        const lastId = lastBookingData.id;

        console.log(lastId);
        // Extract the ID and increment it
        const newBookingId = lastBookingData.id + 1;

        const newBooking = {
          id: newBookingId,
          uid: userId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };

        const bookingResult = await addDoc(bookingRef, newBooking);

        if (bookingResult) {
          handleSuccessfulBooking();
        }
      } else {
        toast.warn("Incorrect Log In Please Try Again");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm your dates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="selectedDate" className="mb-4">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="text" value={formattedStartDate} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="selectedDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="text" value={formattedEndDate} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            Confirm
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default MemberBookModalComponent;
