import { Routes, Route, useLocation, Navigate} from "react-router-dom";
import Home from "./pages/Home";
import Checkout from "./pages/Checkout";
import UserBookingsPage from "./pages/UserBookingsPage";
import Success from "./pages/Success";
import { auth } from "./firebase";
import Cancel from "./pages/Cancel";
import Info from "./pages/Info";
import LoginComponent from "./pages/LoginComponent";
import RegistrationPage from "./pages/RegistrationPage";
import DinerthCottage from "./pages/DinerthCottage";

const App = () => {
  const user = auth.currentUser; // Check if a user is authenticated.
  const location = useLocation();

  return (
    <Routes>
      <Route
        index
        path="/"
        element={<Home />}
      />
      <Route
        index
        path="/dinerth-cottage"
        element={<DinerthCottage />}
      />
      <Route
        path="/login"
        element={user ? <Navigate to="/" /> :<LoginComponent />}
      />
      <Route
        path="/register"
        element={user ? <Navigate to="/" /> : <RegistrationPage />}
      />
      <Route
        path="/info"
        element={<Info />}
      />
      <Route
        path="/checkout"
        element={user ? <Checkout /> : <Navigate to="/" />}
      />
      <Route
        path="/user-bookings"
        element={user ? <UserBookingsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/success"
        element={user ? <Success />: <Navigate to="/" />}
      />
      <Route
        path="/cancel"
        element={user ? <Cancel />: <Navigate to="/" />}
      />
    </Routes>
  );
};

export default App;
