import React, { useState, useEffect } from "react";
import { Alert, Container, Image } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { db } from "../firebase";
import loadingGif from "../components/img/loading.gif";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Success = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const propertyid = searchParams.get("id");
  const EMAILJS_SERVICE_KEY = process.env.REACT_APP_EMAILJS_SERVICE_KEY;
  const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [variables, setVariables] = useState(false);
  const [user, setUser] = useState(null);
  const [property, setProperty] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ref, setRef] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const sendEmail = async () => {
    // Check if the email was sent in the last 5 minutes
    const lastEmailSentTime = localStorage.getItem("lastEmailSentTime");
    const currentTime = new Date().getTime();

    if (
      !lastEmailSentTime ||
      currentTime - lastEmailSentTime >= 5 * 60 * 1000
    ) {
      try {
        await emailjs.send(
          EMAILJS_SERVICE_KEY,
          EMAIL_TEMPLATE_ID,
          {
            property: property,
            email: email,
            name: name,
            startDate: startDate,
            endDate: endDate,
            ref: ref,
            phoneNumber: phoneNumber,
          },
          EMAILJS_PUBLIC_KEY
        );
        setEmailSent(true);

        // Update the last sent time in local storage
        localStorage.setItem("lastEmailSentTime", currentTime);
      } catch (error) {
        console.log(error.text);
      }
    } else {
      // Show an error or toast message indicating the rate limit
      toast.warn("You can send an email only once every 5 minutes.");
    }
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const userBookingsQuery = query(
        collection(db, "bookings"),
        where("uid", "==", user.uid),
        orderBy("id", "desc"), // or "desc" for descending order
        limit(1) // Limit the result to one document
      );
      const userBookings = await getDocs(userBookingsQuery);

      // Define the propertyid here or retrieve it from your data source

      const propertiesRef = collection(db, "properties");
      const propertyDetailsQuery = query(
        propertiesRef,
        where("id", "==", parseInt(propertyid, 10))
      );
      const propertyDetailsSnapshot = await getDocs(propertyDetailsQuery);

      if (!userBookings.empty) {
        const bookingData = userBookings.docs[0].data();
        setStartDate(bookingData.startDate);
        setEndDate(bookingData.endDate);
        setRef(bookingData.id);
        if (!propertyDetailsSnapshot.empty) {
          const propertyData = propertyDetailsSnapshot.docs[0].data();
          setProperty(propertyData.name);
        }

        // Assuming you have a user document for the logged-in user
        // You can query the user data in a similar way
        const usersRef = collection(db, "users");
        const userQuery = query(usersRef, where("uid", "==", user.uid));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          setEmail(user.email);
          setName(userData.name);
          setPhoneNumber(userData.phonenumber);

          // After setting all state variables, mark loading as false and send the email
          setVariables(true);
        }
      }
    } catch (error) {
      console.error("Error fetching user bookings:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserDetails();
    }
  }, [user]);

  useEffect(() => {
    if (variables) {
      sendEmail();
      setLoading(false);
    }
  }, [variables]);

  return (
    <Container
      className="d-flex align-items-center justify-content-center mt-5"
      style={{ height: "100vh" }}
    >
      {loading ? (
        <Image
          src={loadingGif}
          alt="Loading"
          width="140"
          height="auto"
          className="loadingGif"
        />
      ) : emailSent ? (
        <Alert variant="success">
          <Alert.Heading>Booking Confirmed!</Alert.Heading>
          <p>You’ve successfully booked sea breeze apartment</p>
          <p>Your Booking : </p>
          <br />
          <p>Start Date: {startDate}</p>
          <p>End Date: {endDate}</p>
          <br />
          <p>
            You will receive an email shortly with your booking confirmation and
            booking reference number.
          </p>
          <p>You will also receive a receipt of your payment from Stripe.</p>
          <p>For any booking enquiries please contact:</p>
          <p>07815 870 350</p>
          <p>robpristine@gmail.com</p>
          <div className="d-flex justify-content-center">
            <FaCheckCircle size={60} />
          </div>
          <a href="/">Return home</a>
        </Alert>
      ) : (
        <>
          {!loading && !emailSent && (
            <Alert variant="info">
              <Alert.Heading>Loading...</Alert.Heading>
              <p className="mt-2">
                If this error persists please contact supprot@clever-robin.com
              </p>
              <p>Or contact the host directly</p>
              <a href="/">Return home</a>
            </Alert>
          )}
        </>
      )}
    </Container>
  );
};

export default Success;
