import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Gallery from "react-image-gallery";
import mainPic from "../components/img/1.jpg";
import bed1 from "../components/img/bed1.jpg";
import bed1pic2 from "../components/img/bed1Pic2.jpg";
import bed1pic3 from "../components/img/bed1Pic3.jpg";
import bed2 from "../components/img/bed2.jpg";
import dining_room from "../components/img/dining_room.jpg";
import kitchen from "../components/img/kitchen.jpg";
import living_room from "../components/img/living_room.jpg";
import stairs from "../components/img/stairs.jpg";
import twin from "../components/img/twin.jpg";
import twin2 from "../components/img/twin2.jpg";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "../css/index.css";
import propertyData from "../components/property.json";
import { MdLocationPin } from "react-icons/md";
import axios from "axios";
import { DateRange } from "react-date-range";
import MemberBookModalComponent from "../components/modals/MemberBookModalComponent";
import PropertyInformation from "../components/PropertyInformation";
import { toast } from "react-toastify";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import Navigation from "../nav/Navbar";

const PropertyListing = () => {
  const id = 1;
  const [cost, setCost] = useState(110);
  const [nights, setNights] = useState(1);
  const [events, setEvents] = useState([]);
  const [showMemberBookModal, setShowMemberBookModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const API_ENDPOINT = `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_CALENDAR_ID}/events?key=${process.env.REACT_APP_API_KEY}`;
    axios
      .get(API_ENDPOINT)
      .then((response) => {
        const events = response.data.items;
        setEvents(events);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        setIsLoggedIn(true);
      } else {
        // User is not logged in
        setIsLoggedIn(false);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  const unavailableDates = events.map((event) => new Date(event.start.date));

  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (selectedRanges) => {
    const startDate = selectedRanges.selection.startDate;
    const endDate = selectedRanges.selection.endDate;
    const numberOfNights = Math.floor(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    setNights(numberOfNights);
    const newCost = 110 * numberOfNights;
    if (newCost === 0) {
      setCost(110);
    } else {
      setCost(newCost);
    }
    setRanges([selectedRanges.selection]);
  };

  const openMemberBookModal = (ranges) => {
    if (nights) {
      if (nights >= 2) {
        setShowMemberBookModal(true);
      } else {
        toast.error("Must select a minimum of 2 nights");
      }
    } else {
      toast.error("Invalid date selection");
    }
  };

  const closeModal = () => {
    setShowMemberBookModal(false);
  };
  const loginRedirect = () => {
    navigate("/login");
  };

  const propertyImages = {
    images: [
      {
        original: mainPic,
        thumbnail: mainPic,
        description: "Outside",
      },
      {
        original: living_room,
        thumbnail: living_room,
        description: "Living Room",
      },
      {
        original: dining_room,
        thumbnail: dining_room,
        description: "Dining Room",
      },
      {
        original: kitchen,
        thumbnail: kitchen,
        description: "Kitchen",
      },
      {
        original: stairs,
        thumbnail: stairs,
        description: "Stairs",
      },
      {
        original: bed1,
        thumbnail: bed1,
        description: "Main Bedroom Picture 1",
      },
      {
        original: bed1pic2,
        thumbnail: bed1pic2,
        description: "Main Bedroom Picture 2",
      },
      {
        original: bed1pic3,
        thumbnail: bed1pic3,
        description: "Main Bedroom Picture 3",
      },
      {
        original: twin,
        thumbnail: twin,
        description: "Twin Room Picture 1",
      },
      {
        original: twin2,
        thumbnail: twin2,
        description: "Twin Room Picture 2",
      },
      {
        original: bed2,
        thumbnail: bed2,
        description: "Second Double Bedroom",
      },
    ],
  };

  return (
    <>
      <Navigation />
      <Container className="mt-5">
        <Row>
          <Col md={8}>
            <Gallery
              items={propertyImages.images}
              showFullscreenButton={false}
            />
          </Col>
          <Col md={4}>
            <h3 className="fw-bold mb-2">{propertyData.title}</h3>
            <div className="key_features">
              {propertyData.key_features.map((key_features, index, array) => (
                <React.Fragment key={index}>
                  <p className="fs-6 mb-2">{key_features}</p>
                  {index === array.length - 1 ? null : (
                    <p className="mx-1">·</p>
                  )}
                </React.Fragment>
              ))}
            </div>
            <Row>
              <a href="#map" className="text-decoration-underline">
                <MdLocationPin size={28}></MdLocationPin>
                {propertyData.location}
              </a>
            </Row>
            <p className="mt-5 mb-0 fs-5 fw-bold">
              £{propertyData.price}/Night
            </p>
            <p className="text-secondary mb-5">Minimum stay: 2 nights</p>
            <Row>
              <DateRange
                ranges={ranges}
                onChange={handleSelect}
                minDate={new Date()}
                disabledDates={unavailableDates}
              />
            </Row>
            <p className="text-secondary mb-5 ms-4">£110 x {nights} nights</p>
            {isLoggedIn && (
              <Row>
                <Button
                  className="mx-auto w-50"
                  variant="primary"
                  onClick={() => openMemberBookModal(ranges)}
                >
                  Book Now
                </Button>
              </Row>
            )}
            {!isLoggedIn && (
              <Row>
                <Button
                  className="mx-auto w-50"
                  variant="primary"
                  onClick={() => loginRedirect()}
                >
                  Continue
                </Button>
              </Row>
            )}
            <hr />
            {ranges && (
              <p className="fw-bold">
                <span>Total :</span>
                <span className="float-end">£{cost}</span>
              </p>
            )}
          </Col>

          {showMemberBookModal && (
            <MemberBookModalComponent
              closeModal={closeModal}
              nights={nights}
              ranges={ranges}
              id={id}
            />
          )}
        </Row>
        <PropertyInformation />
      </Container>
    </>
  );
};

export default PropertyListing;
